import React, { useState } from 'react'
import './RenderPeople.style.css'
import Badges from '../../badges/Badges'
import { isMobile } from 'react-device-detect';
import ButtonSubmit from '../../ButtonSubmit/ButtonSubmit';
import { useTranslation } from 'react-i18next';
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks';
import { AiOutlineGlobal, AiOutlineDisconnect } from 'react-icons/ai'
import { FiTag } from 'react-icons/fi'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { FiMail, FiFileText } from 'react-icons/fi'
import { MdPersonAddAlt1 } from 'react-icons/md'
import { TbEditCircle, TbPlugConnected } from 'react-icons/tb'
import { FaUserPlus, FaUsers, FaUserTimes } from 'react-icons/fa'
import { color } from '../../../hooks/Utils/color';
import useNotificationPush from '../../../hooks/useNotificationPush/useNotificationPush';
import { api } from '../../../api/api';
import { useNavigate } from 'react-router-dom';


const RenderPeople = ({
    people
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const sendNotification = useNotificationPush()
    const { data: authData } = useAuthReducer()
    const name = `${people?.user_surname} ${people?.user_name}`
    const MAX_LENGTH = 20

    /* BUDDY AND FAN STATES */
    const [inviteSentState, setInviteSentState] = useState(people?.invite_sent)
    const [inviteReceivedState, setInviteReceivedState] = useState(people?.invite_received)
    const [followersState, setFollowersState] = useState(people?.followers)

    /* BUTTON STATE */
    const [buddyButtonState, setBuddyButtonState] = useState(false)
    const [fanButtonState, setFanButtonState] = useState(false)

    const goToProfile = () => {
        navigate(`/profiles/user/${people?.user_username}`)
    }

    /* REQUEST BECOME BUDDY */
    const requestBuddy = async () => {
        try {
            console.log(`Sending request become buddy to user ${people?.user_id}...`)
            setBuddyButtonState(true)
            const body = { user_id: people?.user_id }
            const request = await api('api/profile/request-buddy', 'POST', body, accessToken)
            const response = await request.json()
            console.log('Response request buddy:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setInviteReceivedState([{
                        pivot: {
                            budd_receiver: people?.user_id,
                            budd_response_date: null,
                            budd_sender: authData?.user?.user_id,
                            budd_state: 0,
                        }
                    }])
                    sendNotification(people?.user_id, {
                        title: `${authData?.user?.user_surname} ${authData?.user?.user_name} a vu votre profil`,
                        body: `une demande de pote vous a été envoyé.`,
                        url: `https://myopinion.ci/profiles/user/${authData?.user?.user_id}`
                    })
                }
            }
            setBuddyButtonState(false)
        } catch ({ message }) {
            setBuddyButtonState(false)
            throw new Error(message)
        }
    }

    /* APPROVE BUDDY REQUEST */
    const approveBuddy = async () => {
        try {
            setBuddyButtonState(true)
            const body = { user_id: people?.user_id }
            const request = await api('api/profile/approve-request-buddy', 'POST', body, accessToken)
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setInviteReceivedState([{
                        pivot: {
                            budd_receiver: people?.user_id,
                            budd_response_date: null,
                            budd_sender: authData?.user?.user_id,
                            budd_state: 1,
                        }
                    }])
                    sendNotification(people?.user_id, {
                        title: `${authData?.user?.user_surname} ${authData?.user?.user_name} a vu votre profil.`,
                        body: `${authData?.user?.user_surname} ${authData?.user?.user_name} a accepté votre demande.`,
                        url: `https://myopinion.ci/profiles/user/${authData?.user?.user_id}`
                    })
                }
            }
            setBuddyButtonState(false)
        } catch ({ message }) {
            setBuddyButtonState(false)
            throw new Error(message)
        }
    }

    /* CANCEL BECOME BUDDY */
    const cancelBuddy = async () => {
        try {
            console.log(`Cancel Buddy ${people?.user_id}...`)
            setBuddyButtonState(true)
            const body = { user_id: people?.user_id }
            const request = await api('api/profile/request-cancel-buddy', 'DELETE', body, accessToken)
            const response = await request.json()
            console.log('Response cancel buddy:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setInviteReceivedState([])
                }
            }
            setInviteReceivedState([])
            setBuddyButtonState(false)
        } catch ({ message }) {
            setInviteReceivedState([])
            setBuddyButtonState(false)
            throw new Error(message)
        }
    }



    /* BECOME OR DELETE FAN */
    const becomeOrDeleteFan = async () => {
        setFanButtonState(true)
        try {
            const body = { user_id: people?.user_id }
            const request = await api('api/profile/fan-treatment', 'POST', body, accessToken)
            const response = await request.json()
            console.log('Response become or delete fan:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setFollowersState(['isFan'])
                    if (followersState.length === 0) {
                        sendNotification(people?.user_id, {
                            title: `${authData?.user?.user_surname} ${authData?.user?.user_name} a vu votre profil.`,
                            body: `${authData?.user?.user_surname} ${authData?.user?.user_name} est devenu votre fan.`,
                            url: `https://myopinion.ci/profiles/user/${authData?.user?.user_id}`
                        })
                    } else {
                        setFollowersState([])
                    }
                }
            } else {
                setFollowersState([])
            }
            setFanButtonState(false)
        } catch ({ message }) {
            setFanButtonState(false)
            throw new Error(message)
        }
    }



    /* RENDER BUDDY BUTTON */
    const renderBuddiesButton = () => {

        /* INVITE SENT */
        if (inviteSentState.length !== 0) {

            /* ACCEPT BUDDY */
            if (inviteSentState[0]?.pivot?.budd_receiver === authData?.user?.user_id) {
                return (
                    <button
                        onClick={approveBuddy}
                        className='button text-sm'
                        style={{
                            backgroundColor: 'white',
                            color: "#1880a1",
                            fontWeight: '600',
                            border: '1.8px #1880a1 solid'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <MdPersonAddAlt1 size={18} color={color.primary} />
                        </div>
                        {t('approve')}
                    </button>
                )
            }

            /* CANCEL REQUEST BUDDY */
            if (inviteSentState[0]?.pivot?.budd_sender === authData?.user?.user_id) {
                return (
                    <button
                        onClick={cancelBuddy}
                        className='button text-sm'
                        style={{
                            backgroundColor: 'white',
                            color: "#1880a1",
                            fontWeight: '600',
                            border: '1.8px #1880a1 solid'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <FaUserTimes size={18} color={color.primary} />
                        </div>
                        {t('cancel')}
                    </button>
                )
            }
        }


        /* INVITE RECEIVED */
        if (inviteReceivedState.length !== 0) {

            /* APPROVE BUDDY */
            if (inviteReceivedState[0]?.pivot?.budd_receiver === authData?.user?.user_id) {
                return (
                    <button
                        onClick={requestBuddy}
                        className='button text-sm'
                        style={{
                            backgroundColor: 'white',
                            color: "#1880a1",
                            fontWeight: '600',
                            border: '1.8px #1880a1 solid'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <MdPersonAddAlt1 size={18} color={color.primary} />
                        </div>
                        {t('approve')}
                    </button>
                )
            }

            /* DELETE REQUEST BUDDY SEND*/
            if (inviteReceivedState[0]?.pivot?.budd_sender === authData?.user?.user_id) {
                return (
                    <button
                        onClick={cancelBuddy}
                        className='button text-sm'
                        style={{
                            backgroundColor: 'white',
                            color: "#1880a1",
                            fontWeight: '600',
                            border: '1.8px #1880a1 solid'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <FaUserTimes size={18} color={color.primary} />
                        </div>
                        {t('cancel')}
                    </button>
                )
            }

        } else {
            /* SEND REQUEST BUDDY */
            return (
                <button
                    onClick={requestBuddy}
                    className='button text-sm'
                    style={{
                        backgroundColor: '#1880a1',
                        color: "white",
                    }}>
                    <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                        <FaUserPlus size={15} color='white' />
                    </div>
                    {t('buddy')}
                </button>
            )
        }

    }



    /* RENDER BUDDY BUTTON */
    const renderFanButton = () => {
        if (followersState.length === 0) {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button text-sm'
                    style={{
                        backgroundColor: color.primary,
                        color: color.white,
                    }}>
                    <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                        <TbPlugConnected size={18} color={color.white} />
                    </div>
                    {t('fan')}
                </button>
            )
        } else {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button text-sm'
                    style={{
                        backgroundColor: 'white',
                        color: "#1880a1",
                        fontWeight: '600',
                        border: '1.8px #1880a1 solid'
                    }}>
                    <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                        <AiOutlineDisconnect size={18} color={color.primary} />
                    </div>
                    {t('fan')}
                </button>
            )
        }
    }



    return (
        <div className='render-people' >

            <img onClick={goToProfile} src={people?.profile?.prof_picture ? people?.profile?.prof_picture : require('../../../assets/images/background_opinion.jpg')}
                width={'95%'}
                height={isMobile ? 300 : 200}
                className='profile-picture h-56'
            />
            <div onClick={goToProfile}>
                <h6 className='name'>{name.length > MAX_LENGTH ? `${name.substring(0, MAX_LENGTH)}...` : name}</h6>
                <p className='username'>@{people?.user_username.length > MAX_LENGTH ? `${people?.user_username.substring(0, MAX_LENGTH)}...` : people?.user_username}</p>
            </div>
            <div className='badge-box'>
                <Badges user={people} />
            </div>

            <div className='button-box'>
                {renderBuddiesButton()}
                {renderFanButton()}
            </div>
        </div>
    )
}

export default RenderPeople