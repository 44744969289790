import React, { useCallback, useEffect, useState } from 'react'
import styles from './MessageCard.module.css'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import moment from 'moment';
import 'moment/locale/fr';
import { ReactTinyLink } from 'react-tiny-link';
import Video from './Video/Video';
import Image from './Image/Image';
import Audio from './Audio/Audio';
import { BiCheckDouble, BiTime, BiCheck } from 'react-icons/bi';
import { FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';
import { Alert, Button, IconButton, Menu, MenuItem, Snackbar } from '@mui/material';
import ProfilePicture from 'components/ProfilePicture/ProfilePicture';
import SondDocs from 'components/Opinion/OpinionBody/SondDocs/SondDocs';
import { useTranslation } from 'react-i18next';
import { api } from 'api/api';
import { firestoreDB } from 'services/Firebase/firebase';
import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { deleteDoc, doc } from 'firebase/firestore';
import { color } from 'hooks/Utils/color';
import MessageForward from '../MessageForward/MessageForward';
import messagingKeys from 'constants/messagingKeys';



const MessageCard = ({
    chatId,
    userChat,
    user_id,
    conv_type,
    received,
    message,
    messages = [],
    buddies = [],
    setReplyMessage = () => null,
    setMessages = () => null
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()

    const isMyMessage = message?.user?._id === authData?.user?.user_id
    const timeAgo = moment(message?.createdAt).locale(authData?.language).fromNow();
    const [link, setLink] = useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] = useState('');
    const handleClickSnack = (message) => {
        setMessageSnack(message)
        setOpenSnack(true)
    };
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const [sameUser, setSameUser] = useState(message?.same_user)


    const [showCreateForward, setShowCreateForward] = useState(false)

    const handleShowCreateForward = () => {
        handleClose()
        setShowCreateForward(oldValue => !oldValue)
    }


    useEffect(() => {
        const link = findLinkInText(message?.text);
        setLink(link)
    }, [message?.text])


    const handleCopieText = () => {
        handleClose()
        if (message?.text && message?.text !== 'null') {
            navigator.clipboard.writeText(message?.text)
                .then(() => { })
                .catch(err => console.error('Failed to copy: ', err));
        }
    }

    const handleReply = () => {
        setReplyMessage(message)
    }

    const handleDelete = () => {
        handleClose()
        handleDeleteMessage()
        setReplyMessage(null)

        setMessages(messages?.filter(item =>
            item?._id !== message?._id)
        )
    }

    const findLinkInText = (text) => {
        if (!text) {
            return
        }
        const linkRegex = /(https?:\/\/[^\s]+)/;
        const linkMatch = text.match(linkRegex);
        return linkMatch ? linkMatch[0] : null;
    }


    const handleDeleteMessage = async () => {
        try {
            const docRef = doc(
                firestoreDB,
                scheme,
                firestorekeys.messaging,
                firestorekeys.conversations,
                chatId,
                firestorekeys.messages,
                message?.uid?.toString()
            )

            await deleteDoc(docRef).then(() => {
                console.log('Firestore message deleted.');
            }).catch(() => {
                console.error('Error deleted firestore message');
            })

            const request = await api(
                `api/messages/${message?._id}`,
                'DELETE',
                null,
                accessToken,
            )
            const response = await request.json()
            console.log('Response message:', response)
            handleClickSnack(
                i18n.language === 'en' ?
                    'Message successfully deleted.' :
                    'Message supprimé avec succès.'
            )
            if (request.ok && request.status === 200) {
                if (response.success) {

                }
            }
        } catch ({ message }) {
            // console.log(message)
            throw new Error(message)
        }
    }

    const AttachmentCard = useCallback(({ attachment }) => {
        if (attachment.attach_type === 'document') {
            return (
                <div style={{ marginLeft: '-25px' }}>
                    <SondDocs
                        uri={attachment?.attach_url}
                        style={{ width: '20%', border: '1px #c9c9c9 solid' }}
                        textStyle={{
                            color: isMyMessage ? 'white' : 'black',
                            fontSize: '13px'
                        }}
                    />
                </div>
            )
        }
        if (attachment.attach_type === 'audio') {
            return (
                <Audio attachment={attachment} />
            )
        }
        if (attachment.attach_type === 'image') {
            return (
                <Image attachment={attachment} />
            )
        }
        if (attachment.attach_type === 'video') {
            return (
                <Video attachment={attachment} />
            )
        }
    }, [])

    const messageOptions = () => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {/* <MenuItem onClick={handleClose}>Reponse</MenuItem> */}
                <MenuItem onClick={handleCopieText}>
                    {i18n.language == 'en'
                        ? 'Copy'
                        : 'Copier'
                    }
                </MenuItem>
                <MenuItem onClick={handleReply}>
                    {i18n.language == 'en'
                        ? 'Reply'
                        : 'Repondre'
                    }
                </MenuItem>
                <MenuItem onClick={handleShowCreateForward}>
                    {i18n.language == 'en'
                        ? 'Forward'
                        : 'Transférer'
                    }
                </MenuItem>
                {isMyMessage &&
                    <MenuItem onClick={handleDelete}>
                        {i18n.language == 'en'
                            ? 'Delete'
                            : 'Supprimer'
                        }
                    </MenuItem>
                }
            </Menu>
        )
    }


    if (isMyMessage) {
        return (
            <div className={styles.senderMessageContainer} >
                <div className='text-end flex'>
                    <p className={styles.senderMessageText}>

                        {message?.message_parent?.chat_messages_text &&
                            <p className='text bg-gray-400 px-3 py-2 mt-1 rounded' style={{ fontSize: '13px', color: '#f1f1f1' }}>
                                {message?.message_parent?.chat_messages_text}
                            </p>
                        }

                        {message?.attachments.length !== 0 &&
                            message?.attachments.map((attachment, index) => {
                                return (
                                    <div key={index?.toString()} style={{ width: '25vw', marginTop: '5px' }}>
                                        <AttachmentCard attachment={attachment} />
                                    </div>
                                )
                            })
                        }


                        {(message?.text && message?.text !== 'null') &&
                            <p className='text-start'>
                                {message?.text}
                            </p>
                        }

                        {link &&
                            <ReactTinyLink
                                cardSize="small"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url={link}
                            />
                        }

                        <div className={styles.senderMessagetimestamp}>
                            {message?.forwarded &&
                                <span className='me-1 font-bold'>
                                    {i18n.language
                                        ? 'Forward'
                                        : 'Transféré'
                                    }
                                </span>
                            }
                            {timeAgo}
                            <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                style={{ marginRight: '-15px', height: '15px' }}>
                                <FiMoreHorizontal size={18} color='white' />
                            </IconButton>
                            {isMyMessage && message?.chat_messages_status === 'pending' &&
                                <BiTime size={16} style={{ marginLeft: '10px' }} />
                            }
                            {isMyMessage && message?.chat_messages_status === 'no' &&
                                <BiCheck style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                            }
                            {isMyMessage && message?.chat_messages_status === 'yes' &&
                                <BiCheckDouble style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                            }
                        </div>
                    </p>
                    <img src={authData?.user?.profile?.prof_picture || require('assets/images/background_opinion.jpg')} className='w-9 h-9 ring-1 ring-gray-200 me-2 rounded-full object-cover object-center' alt='' />
                </div>
                {messageOptions()}

                <MessageForward
                    userChat={userChat}
                    chatId={chatId}
                    buddies={buddies}
                    message={message}
                    visible={showCreateForward}
                    onChangeVisible={handleShowCreateForward}
                />

                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                    <Alert onClose={handleCloseSnack} severity={"success"} sx={{ width: '100%' }}>
                        {messageSnack}
                    </Alert>
                </Snackbar>
            </div >
        )
    } else {
        return (
            <div className={`${styles.receiverMessageContainer}`}>
                <ProfilePicture
                    user={message?.sender || received}
                    uri={message?.sender?.profile?.prof_picture || received?.profile?.prof_picture}
                    size={35}
                />
                <div style={{ textAlign: 'left' }}>
                    <p className={styles.receiverMessageText}>

                        {message?.message_parent?.chat_messages_text &&
                            <p className='text bg-gray-400 px-3 py-2 mt-1 rounded' style={{ fontSize: '13px', color: '#f1f1f1' }}>
                                {message?.message_parent?.chat_messages_text}
                            </p>
                        }

                        {message?.attachments?.length !== 0 &&
                            message?.attachments?.map((attachment, index) => {
                                return (
                                    <div key={index?.toString()} style={{ width: '25vw', marginTop: '5px' }}>
                                        <AttachmentCard attachment={attachment} />
                                    </div>
                                )
                            })
                        }

                        {/* USER NAME */}
                        {(
                            conv_type == messagingKeys.groupChat &&
                            message?.user.name &&
                            user_id !== message?.user?._id &&
                            !sameUser
                        ) &&
                            <span style={{
                                fontWeight: '600',
                                color: color.primary,
                                marginHorizontal: 5,
                                marginBottom: 5
                            }}>
                                {message?.user.name}
                            </span>
                        }
                        {(message?.text && message?.text !== 'null') &&
                            <p className='text-start'>
                                {message?.text}
                            </p>
                        }

                        {link &&
                            <ReactTinyLink
                                cardSize="small"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url={link}
                            />
                        }

                        <div className={styles.receiverMessagetimestamp}>
                            {message?.forwarded &&
                                <span className='me-1 font-bold'>
                                    {i18n.language
                                        ? 'Forward'
                                        : 'Transféré'
                                    }
                                </span>
                            }
                            {timeAgo}
                            <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                style={{ marginRight: '-10px', height: '15px', }}>
                                <FiMoreHorizontal size={18} color='#414141' />
                            </IconButton>
                        </div>
                    </p>
                </div>
                {messageOptions()}
                <MessageForward
                    userChat={userChat}
                    chatId={chatId}
                    buddies={buddies}
                    message={message}
                    visible={showCreateForward}
                    onChangeVisible={handleShowCreateForward}
                />
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                    <Alert onClose={handleCloseSnack} severity={"success"} sx={{ width: '100%' }}>
                        {messageSnack}
                    </Alert>
                </Snackbar>
            </div >
        )
    }
}

export default MessageCard