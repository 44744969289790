import './translations/IMLocalize'
import AppStack from './routes/AppStack';
import AuthStack from './routes/AuthStack';
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { HelmetProvider } from 'react-helmet-async';


function App() {

  const { data: AuthData } = useAuthReducer()
  const isAuthenticated = AuthData?.authorization
  localStorage.setItem('language', AuthData?.language)

  if (AuthData?.isChangeAccount) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner style={{ margin: '45vh' }} />
      </div>
    )
  }

  if (isAuthenticated) {
    return (
        <HelmetProvider>
          <AppStack />
        </HelmetProvider>
    )
  } else {
    return (
      <AuthStack />
    )
  }

}

export default App;
