import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import styles from './LiveStream.module.css'
import {
  AgoraRTCProvider,
  LocalUser,
  LocalVideoTrack,
  RemoteUser,
  useClientEvent,
  useIsConnected,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRemoteVideoTracks,
  useRTCClient,
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { agoraAppID } from "constants/env";
import { collection, deleteDoc, doc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import firestorekeys from "constants/firestorekeys";
import { firestoreDB } from "services/Firebase/firebase";
import { FaUser } from "react-icons/fa";
import { useAccountReducer } from "hooks/ReducerHooks/ReducerHooks";
import { useAuthReducer } from "hooks/ReducerHooks/ReducerHooks";
import { IoIosCloseCircle } from "react-icons/io";
import { SiSlideshare } from "react-icons/si";
import { IoIosShareAlt } from "react-icons/io";
import { MdSupervisorAccount } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { CgComment } from "react-icons/cg";
import { Spinner } from "react-bootstrap";
import avatar from 'assets/images/background_opinion.jpg'
import { useTranslation } from "react-i18next";
import LiveInvite from "../Componants/LiveInvite/LiveInvite";
import LiveShare from "../Componants/LiveShare/LiveShare";
import LiveComment from "../Componants/LiveComment/LiveComment";
import { scheme } from "constants/env";
import { useAgoraContext } from "contexts/AgoraContext";
import { agorakeys } from "constants/agorakeys";


const LiveStream = () => {

  const { t, i18n } = useTranslation()
  const { data: authContextState } = useAuthReducer()
  const navigate = useNavigate();
  const location = useLocation();
  const { live } = location?.state;

  // AGORA CLIENT
  const client = useRef(null)
  const localMicrophoneTrack = useRef(null);
  const localCameraTrack = useRef(null);

  const [appID, setAppID] = useState(agoraAppID)
  const [uid, setUid] = useState(0)
  const channelName = live?.live_channel_name
  const token = live?.live_code


  // LOCAL USER
  const [started, setStarted] = useState(false);
  const [joined, setJoined] = useState(false);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);


  // LIVE STATES
  const [isLiked, setIsLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(0)
  const [commentCount, setCommentCount] = useState(0)
  const [audienceCount, setAudienceCount] = useState(0)

  // MODAL STATE
  const [showInviteLive, setShowInviteLive] = useState(false)
  const [showShareLive, setShowShareLive] = useState(false)

  const handleShowShareLive = () => setShowShareLive(oldValue => !oldValue)
  const handleShowInviteLive = () => setShowInviteLive(oldValue => !oldValue)


  // AGORA INIT
  useEffect(() => {
    const agoaraInit = async () => {
      try {
        if (joined) return

        const agoraClient = AgoraRTC.createClient({ 
          mode: "live", 
          codec: "vp8",
          logConfig: {
            level: 0, // Suppresses all logs
            // Options:
            // 0: NONE
            // 1: CRITICAL
            // 2: ERROR
            // 3: WARNING
            // 4: INFO (default)
            // 5: DEBUG
          },
        });
        client.current = agoraClient
        agoraClient.setClientRole(agorakeys.broadcasterRole)
        console.log('Response agora init:', agoraClient)

        const { } = await agoraClient.join(
          appID,
          channelName,
          token || null,
          uid || null,
        );


        const [localAudioTrack, localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
        localAudioTrack.setEnabled(true)
        localVideoTrack.setEnabled(true)

        localMicrophoneTrack.current = localAudioTrack
        localCameraTrack.current = localVideoTrack

        localVideoTrack.play("local-stream");


        // EVENT LISTENER
        let localUserUid = null

        agoraClient.on("user-joined", (user) => {
          if (localUserUid) return
          console.log("user-joined:", user);

          setUid(user.uid)
          localUserUid = user.uid

          setJoined(true);
        })

        agoraClient.on("user-published", async (user, mediaType) => {
          if (user.uid == localUserUid) return

          // Subscribe to a remote user
          await agoraClient.subscribe(user, mediaType);
          console.log("subscribe success", user.uid);

          if (mediaType === "video" || mediaType === "all") {
            // Get `RemoteVideoTrack` in the `user` object.
            const remoteVideoTrack = user.videoTrack;
            console.log(remoteVideoTrack);

            user.videoTrack.play(`${user.uid}`);
          }

          if (mediaType === "audio" || mediaType === "all") {
            // Get `RemoteAudioTrack` in the `user` object.
            const remoteAudioTrack = user.audioTrack;
            // Play the audio track. Do not need to pass any DOM element
            remoteAudioTrack.play();
          }
        });

        agoraClient.on("user-unpublished", (user) => {
          console.log("user-unpublished:", user);
        });

        //  PUBLISH
        await client.current.publish([localAudioTrack, localVideoTrack])

      } catch (error) {
        console.error('Error init agora', error);
      }
    }
    agoaraInit()

    return () => {
      leaveChannel()
    };
  }, []);



  const leaveChannel = async () => {
    if (!client.current) return;
    setJoined(false)

    localMicrophoneTrack.current && localMicrophoneTrack.current.stop();
    localCameraTrack.current && localCameraTrack.current.stop();
    await client.current.unpublish([localMicrophoneTrack.current, localCameraTrack.current]);


    localMicrophoneTrack.current && localMicrophoneTrack.current.close();
    localCameraTrack.current && localCameraTrack.current.close();

    await client.current.removeAllListeners();
    await client.current.leave();

    setJoined(false);
    localMicrophoneTrack.current = null;
    localCameraTrack.current = null;

    handleRemoveAudience()
    handleEndLive()

    navigate('/')
  };


  // AUDIENCES MANAGEMENT
  useEffect(() => {
    if (joined) {
      // Add audience
      handleAddAudience(authContextState?.user?.user_id?.toString(), {
        ...authContextState?.user,
        live_remote_uid: authContextState?.user?.user_id
      })
    }
  }, [joined])

  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.audiences
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAudienceCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  // UPDATE AUDIENCE
  const handleAddAudience = async (id, data) => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.audiences
      );

      const refDoc = doc(collectionRef, id)

      await setDoc(refDoc, data).then(() => {
        console.log('Live audience added.');
      }).catch((error) => {
        console.error('Error audience added:', error);
      });
    } catch (error) {
      console.error('Error audience added:', error);
    }
  }


  const handleRemoveAudience = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.audiences
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      await deleteDoc(refDoc).then(() => {
        console.log('Live remove audience.');
        handleEndLive()
      }).catch((error) => {
        console.error('Error remove audience:', error);
      });
    } catch (error) {
      console.error('Error remove audience:', error);
    }
  }


  const handleEndLive = async () => {
    try {
      const refDoc = doc(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName
      )

      await updateDoc(refDoc, { live_finished: true }).then(() => {
        console.log('Live finished.');
        navigate('/')
      }).catch((error) => {
        console.error('Error live finished:', error);
      });
    } catch (error) {
      console.error('Error live finished:', error);
    }
  }

  // LIKES MANAGEMENT
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.likes
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setIsLiked(data?.some(item => item?.id == authContextState?.user?.user_id))
      setLikeCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  const handleLike = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.likes
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      if (isLiked) {
        await deleteDoc(refDoc).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      } else {
        await setDoc(refDoc, authContextState?.user).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      }
    } catch (error) {
      console.error('Error like added:', error);
    }
  }


  // if (deviceLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div>
  //         <Spinner
  //           size="sm"
  //           role="status"
  //           aria-hidden="true"
  //           animation="border"
  //           variant={'secondary'}
  //         // className="me-3"
  //         />
  //         <p>{' '} {i18n.language == 'en' ? 'Loading devices...' : 'Chargement des périphériques audio et vidéo....'}</p>
  //       </div>
  //     </div>
  //   );
  // }

  // if (publishData.isLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div>
  //         <Spinner
  //           size="sm"
  //           role="status"
  //           aria-hidden="true"
  //           animation="border"
  //           variant={'secondary'}
  //         // className="m-3"
  //         />
  //         <p>{' '}  {i18n.language == 'en' ? 'Loading...' : 'Chargement....'}</p>
  //       </div>
  //     </div>
  //   );
  // }

  // if (!joined) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div>
  //         <Spinner
  //           size="sm"
  //           role="status"
  //           aria-hidden="true"
  //           animation="border"
  //           variant={'secondary'}
  //         />
  //         <p>{' '} Connexion...</p>
  //       </div>
  //     </div>
  //   );
  // }


  return (
    <div className={`${styles.page} h-screen overflow-hidden bg-black w-3/3`}>

      <div className={`${styles.container} w-full h-full relative items-baseline`}>

        {/* <div className={`${styles.localUser} h-screen`} >
          <LocalVideoTrack
            videoTrack={localCameraTrack}
            cameraOn
            micOn
            playAudio
            playVideo
          />
        </div> */}


        <div id="local-stream" className={`local-stream w-full mx-auto h-screen bg-black`}>

        </div>

        <div>
          {/* HEADER */}
          <div className="w-full top-0 absolute flex justify-between items-center p-3" >

            <div className="flex items-center">
              <div className="bg-red-500 text-white px-3 rounded-full">
                Live
              </div>
              <div className="flex items-center mx-2 px-3 text-white bg-neutral-900 rounded-full">
                <FaUser />
                <span className="ms-1 font-bold">
                  {audienceCount || 0}
                </span>
              </div>
            </div>

            <button onClick={leaveChannel} className="cursor-pointer">
              <IoIosCloseCircle color="white" size={32} />
            </button>

          </div>

          <div className="flex items-end justify-between w-full absolute bottom-32 lg:bottom-20 p-3 mt-auto">

            <div className="w-96 text-white">
              <LiveComment
                channelName={channelName}
                onChangeCommentCount={setCommentCount}
              />
            </div>

            <div className="me-2">
              <button onClick={handleLike}>
                <FaHeart color={likeCount != 0 ? 'red' : "white"} size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {likeCount || 0}
                </p>
              </button>

              <div className="mt-3">
                <CgComment color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {commentCount || 0}
                </p>
              </div>
            </div>

          </div>

          {/* BOTTOM */}
          <div className="flex items-center justify-between w-full absolute bottom-16 lg:bottom-0 p-3 mt-auto">

            {/* USER */}
            <div className="flex p-1 items-center bg-neutral-900 w-72 rounded-full me-3">
              <img src={authContextState?.user?.profile?.prof_picture || avatar} alt="" className="w-10 h-10 rounded-full me-1" />
              <div className="ps-2 pe-4">
                <h3 className="text-white font-bold">{authContextState?.user?.user_surname} {authContextState?.user?.user_name}</h3>
                <span className="text-xs text-white ">@{authContextState?.user?.user_username}</span>
              </div>
            </div>

            <div className="flex items-center justify-end w-96">
              <button className="me-8">
                <SiSlideshare color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Hosts'
                    : 'Hôtes'
                  }
                </p>
              </button>

              <button onClick={handleShowInviteLive} className="me-8">
                <MdSupervisorAccount color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Invite'
                    : 'Inviter'
                  }
                </p>
              </button>

              <button onClick={handleShowShareLive}>
                <IoIosShareAlt color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Share'
                    : 'Partager'
                  }
                </p>
              </button>
            </div>

          </div>

        </div>
      </div>

      {/* INVITE USER */}
      <LiveInvite
        live={live}
        visible={showInviteLive}
        onChangeVisible={handleShowInviteLive}
      />

      {/* SHARE LIVE */}
      <LiveShare
        live={live}
        visible={showShareLive}
        onChangeVisible={handleShowShareLive}
      />

    </div>
  )
}

export default LiveStream