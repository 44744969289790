export const color = {
    primary: '#1880a1',
    primaryLight: '#B9D8E2',
    secondary: '#003E6B',
    success: '#28a745',
    info: '#0570E7',
    gray: '#F2F2F4',
    white: '#FFFFFF',
    black: '#000000',
    danger: '#e74c3c',
    dark: '#424242',
    grayeTain: '#DCDCDC',
    grayLight: '#D3D3D3',
    grayArgent: '#C0C0C0',
    grayDark: '#A9A9A9',
}