import React, { useEffect, useState } from 'react';
import './Topbar.style.css';
import { Link } from 'react-router-dom';
import { color } from '../../hooks/Utils/color';
import { MdNotificationsNone } from 'react-icons/md';
import { IoSearch } from 'react-icons/io5';
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import { Image } from 'react-bootstrap';
import { api } from '../../api/api';

const TopBar = () => {

  const { data: AuthData } = useAuthReducer()
  const accessToken = useAccessToken()
  const [logo, setLogo] = useState(localStorage.getItem('logo-header'));
  const [showMenu, setShowMenu] = useState(false);



  function openNakaniLink() {
    window.open('https://nakani.info', '_blank', 'noopener,noreferrer');
  }


  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };


  /* GET NEW LOGO */
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const request = await api(`api/assets/header_logo`, 'GET', null, accessToken)
        const response = await request.json()
        // console.log('Response fetch logo:', response)
        if (request.ok && request.status === 200) {
          setLogo(response?.url)
          localStorage.setItem('logo-header', response?.url);
        }
      } catch ({ message }) {
        fetchLogo()
        throw new Error(message)
      }
    }
    fetchLogo()
  }, [])


  return (
    <div className="topbar">
      <img
        src={logo ? logo : require('../../assets/images/myopinion_text.png')}
        style={{
          width: '150px',
          marginBottom: '-20px'
        }}
        alt=''
      />
      <nav>
        <ul style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>



          <li style={{ marginRight: '20px' }}>
            <div onClick={openNakaniLink}>
              <img
                src={require('assets/images/nakani-icon.jpg')}
                style={{ width: 30, height: 30, marginRight: 12 }}
              />
            </div>
          </li>

          <li style={{ marginRight: '20px' }}>
            <Link to={'/search'}>
              <IoSearch size={30} color={'#808080'} />
            </Link>
          </li>

          <li style={{ marginRight: '20px' }}>
            <Link to={'/notification'}>
              <MdNotificationsNone size={30} color={'#808080'} />
            </Link>
          </li>

          <li style={{ marginRight: '20px' }}>
            <Link to={`/profiles/user/${AuthData?.user?.user_username}`}>
              <Image
                alt=""
                src={AuthData?.user?.profile?.prof_picture}
                width="35"
                height="35"
                roundedCircle={true}
                className="d-inline-block align-top ms-2"
              />
            </Link>
          </li>

        </ul>
      </nav>
    </div>
  );
};

export default TopBar;
