import Constants from "../constants/Constants"

export const apiUrl = process.env.REACT_APP_API_URL
export const appUrl = process.env.REACT_APP_WEB_URL

export const api = async (
    endpoint,
    method,
    bodyValue = null,
    endpointHeaders = {},
) => {
    
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        ...endpointHeaders,
    }

    const body = JSON.stringify(bodyValue)

    const request = fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        method,
        headers,
        body: method === 'GET' ? null : body,
    })
    return request
}