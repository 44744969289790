// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCRfaoFT-PjW4XpAlVcjXpjdO9Ya8yJprI",
    authDomain: "myopinion-37af3.firebaseapp.com",
    projectId: "myopinion-37af3",
    storageBucket: "myopinion-37af3.firebasestorage.app",
    messagingSenderId: "493029783865",
    appId: "1:493029783865:web:48c293cf196514eb0f50e8",
    measurementId: "G-81XQSP4ZPP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestoreDB = getFirestore(app)

export const firestoreKeystore = process.env.REACT_APP_SCHEME