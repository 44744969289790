import React, { useEffect, useState } from 'react'
import './Opinion.style.css'
import { AiOutlineEye, AiFillGift, AiOutlineLike } from 'react-icons/ai'

import Moment from 'react-moment'
import OpinionShare from '../OpinionShare/OpinionShare'
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import CommentInput from '../CommentInput/CommentInput'
import OpinionFooter from './OpinionFooter/OpinionFooter'
import moment from 'moment'
import { MdOutlinePublic } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import ViewOpinion from '../viewOpinion/ViewOpinion'
import { api } from '../../api/api'
import { useAccessToken } from '../../hooks/ReducerHooks/ReducerHooks'
import { Modal } from 'react-bootstrap'
import useReducerHomeAction from '../../hooks/ReducerHooksAction/useReducerHomeAction'
import Profile from '../ProfileContent/ProfileContent'
import ProfileCard from '../ProfileCard/ProfileCard'
import SondVideo from './OpinionBody/SondVideo/SondVideo'
import SondAttachment from './SondAttachment/SondAttachment'
import OpinionBody from './OpinionBody/OpinionBody'
import OpinionHeader from './OpinionHeader/OpinionHeader'
import PeopleMatchCarousel from '../PeopleMatchCarousel/PeopleMatchCarousel'


const Opinion = ({
    index,
    opinion,
    userIsMuted = false,
    updateOpinion = () => null,
    deleteOpinion = () => null,
    handleMuteUser = () => null,
    sourceFrom
}) => {

    const navigate = useNavigate()
    const accessToken = useAccessToken()
    Moment.globalLocale = localStorage.getItem('language');
    const [showDetail, setShowDetail] = useState(false)
    const [showProfile, setShowProfile] = useState(false)




    useEffect(() => {
        const updateViewOpinion = async () => {
            try {
                if (opinion?.sond_id && sourceFrom != 'Feed') {
                    const request = await api(
                        `api/opinions/${opinion?.sond_id}/view`,
                        'POST',
                        null,
                        accessToken
                    )
                    const response = await request.json()
                    // console.log('Response update view opinion:', response)
                }
            } catch (error) {
                console.log('Response update view opinion: ', error)
            }
        }
        updateViewOpinion()
    }, [opinion?.sond_id])



    return (
        <div className='opinion overflow-hidden' style={{ borderBottom: sourceFrom != 'ViewOpinion' ? '8px #f8f6f6 solid;' : 'none' }}>

            <OpinionHeader
                opinion={opinion}
                user={opinion?.user}
                userIsMuted={userIsMuted}
                user_sond_muted={opinion?.user_sond_muted}
                sond_user={opinion?.sond_user}
                sond_id={opinion?.sond_id}
                sond_caption={opinion?.sond_caption}
                sond_picture={opinion?.sond_picture}
                attachments={opinion?.attachments}
                sond_finished={opinion?.sond_finished_at}
                updateOpinion={updateOpinion}
                deleteOpinion={deleteOpinion}
                handleMuteUser={handleMuteUser}
                sourceFrom={sourceFrom}
            />

            <OpinionBody
                opinion={opinion}
                viewOpinion={showDetail}
                setViewOpinion={setShowDetail}
                sourceFrom={sourceFrom}
                updateOpinion={updateOpinion}
            />

            {/* RENDER SHARE OPINION */}
            {opinion?.shared_opinion_data &&
                <OpinionShare
                    opinion={opinion?.shared_opinion_data}
                    viewOpinion={showDetail}
                    setViewOpinion={setShowDetail}
                    sourceFrom={sourceFrom}
                    updateOpinion={updateOpinion}
                />
            }

            <OpinionFooter
                sond_id={opinion?.sond_id}
                ago_id={opinion?.agora?.ago_id}
                sond_user={opinion?.sond_user}
                sond_caption={opinion?.sond_caption}
                last_comment={opinion?.last_comment}
                comments={opinion?.comments}
                comments_count={opinion?.comments_count}
                soutiens={opinion?.soutiens}
                soutiens_count={opinion?.soutiens_count}
                suggestions={opinion?.suggestions}
                suggestions_count={opinion?.suggestions_count}
                votes_count={opinion?.votes_count}
                get_user_vote={opinion?.get_user_vote}
                shares={opinion?.shares}
                shares_count={opinion?.shares_count}
                updateOpinion={updateOpinion}
                sourceFrom={sourceFrom}
            />


            {/* RENDER MODAL VIEW OPINION */}
            <ViewOpinion
                opinion={opinion}
                show={showDetail}
                setShow={setShowDetail}
                updateOpinion={updateOpinion}
                sourceFrom={'Home'}
            />

            {/* RENDER VIEW PROFILE */}
            <Profile
                user_id={opinion?.sond_user}
                show={showProfile}
                setShow={setShowProfile}
            />

        </div>
    )
}

export default Opinion