import React, { useEffect, useState } from 'react'
import './Sidebar.style.css'
import logo_text from '../../assets/images/myopinion_text.png';
import { GoSignOut } from "react-icons/go";
import { FaUserCircle, FaUsers } from 'react-icons/fa'
import { MdOutlinePublic, MdAddBox, MdGroups, MdMoreHoriz, MdAdd, MdLiveTv } from 'react-icons/md'
import { AiFillMessage } from 'react-icons/ai'
import { Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction';
import { useAuthReducer, useOneToOneReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import Profile from '../ProfileContent/ProfileContent';
import ModelAlert from '../ModalAlert/ModelAlert';
import Badges from '../badges/Badges';
import ModalCreateOpinion from '../ModalCreateOpinion/ModalCreateOpinion';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from 'react-icons/io5';
import { Badge } from '@mui/material';
import { color } from 'hooks/Utils/color';



const Sidebar = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: AuthData } = useAuthReducer()
  const language = localStorage.getItem('language')
  const { data: oneToOneStore } = useOneToOneReducer()

  const [showMenu, setShowMenu] = useState(false)
  const { LOGOUT } = useReducerAuthAction()
  const [showProfile, setShowProfile] = useState(false)
  const [showAddOpinion, setShowAddOpinion] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const [currentPage, setCurrentPage] = useState('/Home')

  useEffect(() => {
    if (location?.pathname === '/') {
      setCurrentPage('home')
    }
    if (location?.pathname === '/live') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/home') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/public') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/one-to-one') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/agora') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/search') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
  }, [location])

  // LOGOUT
  const logout = () => {
    LOGOUT()
  }

  function openNakaniLink() {
    window.open('https://nakani.info', '_blank', 'noopener,noreferrer');
  }

  return (
    <div className='sidebar'>

      <div className='logo-box'>
        <img src={logo_text} className='logo' alt='' />
      </div>

      <div className='profile-box'>

        <Link to={`/profiles/user/${AuthData?.user?.user_username}`} style={{ textDecoration: 'none' }} className='user-box'>

          <button>
            <Image
              alt=""
              src={AuthData?.user?.profile?.prof_picture}
              width="50"
              height="50"
              roundedCircle={true}
              className="d-inline-block align-top ms-2"
            />
          </button>

          <div className='name-box ms-2'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className='name'>
                {AuthData?.user?.user_surname} {AuthData?.user?.user_name}
              </p>
              {(AuthData?.user?.user_verified || AuthData?.user?.user_verified_from_revenue_cat) &&
                <img src={require('assets/images/verified.png')}
                  style={{
                    width: 17,
                    height: 17,
                    marginLeft: 5,
                    marginTop: 2
                  }}
                />
              }

              {(AuthData?.user?.user_gold || AuthData?.user?.user_gold_from_revenue_cat) &&
                <img src={require('assets/images/premium.png')}
                  style={{
                    width: 17,
                    height: 17,
                    marginLeft: 5,
                    marginTop: 2
                  }}
                />
              }
            </div>

            <p className='username'>
              @{AuthData?.user?.user_username}
            </p>
            <Badges user={AuthData?.user} />
          </div>

        </Link>

        {/* <div style={{ width: '20px' }}>
          <MdMoreHoriz onClick={() => setShowMenu(!showMenu)} size={24} color="#808080" style={{ cursor: 'pointer' }} />
        </div> */}

        {showMenu && (
          <div style={{ cursor: 'pointer' }} className='menu shadow'>
            <div
              onClick={() => {
                setShowMenu(false)
                setShowProfile(true)
              }}
              className='mb-2'>
              <FaUserCircle size={18} color='gray' />
              <Link to={`/profiles/user/${AuthData?.user?.user_username}`} style={{ textDecoration: 'none', color: '#222', marginLeft: '18px' }} >
                {t('profile')}
              </Link>
            </div>
            <div onClick={logout} style={{ cursor: 'pointer' }}>
              <GoSignOut style={{ marginLeft: '1px' }} size={18} color='gray' />
              <button>{t('logout')}</button>
            </div>
          </div>
        )}

      </div>

      {/* <div onClick={() => navigate('home', { options: { replace: false } })} className='box'>
        <FaUsers size={26} className='icon' />
        <span className='title'>{t('buddies')}</span>
      </div> */}

      <div onClick={() => navigate('live')} className='box'>
        <MdLiveTv
          className='icon'
          size={32}
          style={{
            width: '30px', height: '30px',
            color: currentPage === 'live'
              ? color.primary
              : '#808080'
          }}
        />
        <span
          className='title'
          style={{
            color: currentPage === 'live'
              ? color.primary
              : '#222'
          }}>
          Live
        </span>
      </div>

      <div onClick={() => navigate('public')} className='box'>
        <MdOutlinePublic
          size={32}
          className='icon'
          style={{
            width: '30px', height: '30px',
            color: currentPage === 'public'
              ? color.primary
              : '#808080'
          }}
        />
        <span
          className='title'
          style={{
            color: currentPage === 'public'
              ? color.primary
              : '#222'
          }} >
          Opinions
        </span>
      </div>

      {/* <div onClick={() => navigate('one-to-one')} className='box'>
        <Badge badgeContent={oneToOneStore?.message_number || 0} color="error" style={{ marginRight: '15px' }}>
          <AiFillMessage size={30} style={{ width: '30px', height: '30px', color: '#616060' }} />
        </Badge>
        <span className='title'>One to One</span>
      </div> */}

      <div onClick={() => navigate('one-to-one')} className='box'>
        <Badge badgeContent={oneToOneStore?.message_number || 0} color="error" style={{ marginRight: '15px' }}>
          <AiFillMessage
            size={30}
            style={{
              width: '30px', height: '30px',
              color: currentPage === 'one-to-one'
                ? color.primary
                : '#808080'
            }}
          />
        </Badge>
        <span
          className='title'
          style={{
            color: currentPage === 'one-to-one'
              ? color.primary
              : '#222'
          }} >
          Inbox
        </span>
      </div>

      <div
        onClick={() => navigate('agora', { options: { replace: false } })}
        className='box'>
        <MdGroups
          size={28}
          className='icon'
          style={{
            color: currentPage === 'agora'
              ? color.primary
              : '#808080'
          }}
        />
        <span className='title' style={{
          color: currentPage === 'agora'
            ? color.primary
            : '#222'
        }} >
          Agoras
        </span>
      </div>

      <div
        onClick={() => navigate('search', { options: { replace: false } })}
        className='box'>
        <IoSearchSharp
          size={28}
          className='icon'
          style={{
            color: currentPage === 'search'
              ? color.primary
              : '#808080'
          }}
        />
        <span className='title' style={{
          color: currentPage === 'search'
            ? color.primary
            : '#222'
        }} >Recherche</span>
      </div>

      <div
        onClick={openNakaniLink}
        className='box'>
        <img
          src={require('assets/images/nakani-icon.jpg')}
          style={{ width: 30, height: 30, marginRight: 12 }}
        />
        <span className='title' style={{
          color: currentPage === 'search'
            ? color.primary
            : '#222'
        }} >Nakani AI</span>
      </div>

      <div onClick={() => setShowAddOpinion(true)} className='box-opinion'>
        <MdAdd size={30} className='icon' />
        <span className='title'>Opinion</span>
      </div>

      <Profile
        user_id={AuthData?.user?.user_id}
        item={AuthData?.user}
        show={showProfile}
        setShow={setShowProfile}
        sourceFrom={'Home'}
      />

      <ModalCreateOpinion
        user={AuthData?.user}
        show={showAddOpinion}
        setShow={setShowAddOpinion}
      />

      <ModelAlert
        message={language == 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
        show={showAlert}
        setShow={setShowAlert}
      />

    </div>
  )
}

export default Sidebar