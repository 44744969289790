import React from 'react'
import styles from './Public.module.css'
import { useTranslation } from 'react-i18next';
import TopBar from 'components/Topbar/Topbar';
import HomeOpinions from 'components/Opinions/HomeOpinions/HomeOpinions';
import RandomOpinions from 'components/Opinions/PublicOpinions/RandomOpinions/RandomOpinions';
import RecentOpinions from 'components/Opinions/PublicOpinions/RecentOpinions/RecentOpinions';
import ViralOpinions from 'components/Opinions/PublicOpinions/ViralOpinions/ViralOpinions';


const Public = () => {

  const { t, i18n } = useTranslation()
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  return (
    <div className={styles.page}>
      <TopBar />
      {/* TAB */}
      <div className={styles.tab}>
        <span style={{paddingBottom: '10px'}} onClick={() => handleTabChange(0)} className={tabIndex === 0 ? styles.tabLabelActive : styles.tabLabel}>
          {t('buddies')}
        </span>
        <span style={{paddingBottom: '10px'}} onClick={() => handleTabChange(1)} className={tabIndex === 1 ? styles.tabLabelActive : styles.tabLabel}>
          {t('forYou')}
        </span>
        <span style={{paddingBottom: '10px'}} onClick={() => handleTabChange(2)} className={tabIndex === 2 ? styles.tabLabelActive : styles.tabLabel}>
          {t('recent')}
        </span>
        <span style={{paddingBottom: '10px'}} onClick={() => handleTabChange(3)} className={tabIndex === 3 ? styles.tabLabelActive : styles.tabLabel}>
          {t('viral')}
        </span>
      </div>
      {/* TAB PANEL */}
      <div className={tabIndex === 0 ? styles.tabViewActive : styles.tabView}>
        <HomeOpinions />
      </div>
      <div className={tabIndex === 1 ? styles.tabViewActive : styles.tabView}>
        <RandomOpinions />
      </div>
      <div className={tabIndex === 2 ? styles.tabViewActive : styles.tabView}>
        <RecentOpinions />
      </div>
      <div className={tabIndex === 3 ? styles.tabViewActive : styles.tabView}>
        <ViralOpinions />
      </div>
    </div>
  )
}

export default Public