import React from 'react'
import { useState } from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Profile from '../ProfileContent/ProfileContent'
import './ProfilePicture.style.css'

const ProfilePicture = ({
    user,
    uri,
    size = 30,
}) => {

    const [showProfile, setShowProfile] = useState(false)

    return (
        <Link to={`/profiles/user/${user?.user_username}`}>
            <div>
                <img
                    src={uri ? uri :
                        user?.user_civility === 'F' ?
                            require('assets/picture_women.jpeg') :
                            require('assets/picture_men.jpeg')
                    }
                    className='profile-picture-componant'
                    width={size}
                    height={size}
                    style={{ width:size, height: size,  borderRadius: '100%' }}
                    alt=""
                />

                {/* RENDER VIEW PROFILE */}
                <Profile
                    user_id={user?.user_id}
                    show={showProfile}
                    setShow={setShowProfile}
                />
            </div>
        </Link>
    )
}

export default ProfilePicture