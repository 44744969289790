import React, { useEffect, useState } from 'react'
import './NavigationBottom.style.css'
import { Link, useLocation } from 'react-router-dom'
import { FaUsers } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { MdAddBox, MdGroups, MdOutlinePublic } from 'react-icons/md'
import { AiFillMessage } from 'react-icons/ai'
import { IoSearchSharp } from 'react-icons/io5'
import { color } from '../../hooks/Utils/color'
import { useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import ModalCreateOpinion from '../ModalCreateOpinion/ModalCreateOpinion'

const NavigationBottom = () => {

  const { t } = useTranslation()
  const { data: AuthData } = useAuthReducer()
  const [showAddOpinion, setShowAddOpinion] = useState(false)
  const currentPath = window.location.pathname;
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState('/Home')

  useEffect(() => {
    if (location?.pathname === '/') {
      setCurrentPage('home')
    }
    if (location?.pathname === '/live') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/home') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/public') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/one-to-one') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/agora') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
    if (location?.pathname === '/search') {
      setCurrentPage(location?.pathname?.replace('/', ''))
    }
  }, [location])

  return (
    <div style={{
      display: location?.pathname?.includes('one-to-one/') ||
        location?.pathname?.includes('in-mail/new') ||
        location?.pathname?.includes('one-to-one/') ||
        location?.pathname?.includes('conversation/')
        ? 'none'
        : 'block'
    }}>
      <nav className="container">
        <ul>

          {/* <li>
          <Link to={'/home'}>
            <div style={{ flexDirection: 'column' }}>
              <FaUsers size={30} color={color.primary} />
              <span style={{ fontWeight: '600', fontSize: '12px', color: color.primary }}>
                {t('buddies')}
              </span>
            </div>
          </Link>
        </li> */}

          <li>
            <Link to={'/live'}>
              <div style={{ flexDirection: 'column' }}>
                <MdOutlinePublic
                  size={30}
                  color={currentPage == 'live' ? color.primary : 'gray'}
                  className='mx-auto'
                />
                <span style={{ fontWeight: '600', fontSize: '12px', color: currentPage == 'live' ? color.primary : 'gray' }}>
                  Live
                </span>
              </div>
            </Link>
          </li>

          <li>
            <Link to={'/public'}>
              <div style={{ flexDirection: 'column' }}>
                <MdOutlinePublic
                  size={30}
                  color={currentPage == 'public' ? color.primary : 'gray'}
                  className='mx-auto'
                />
                <span style={{ fontWeight: '600', fontSize: '12px', color: currentPage == 'public' ? color.primary : 'gray' }}>
                  Opinions
                </span>
              </div>
            </Link>
          </li>

          <li>
            <Link onClick={() => setShowAddOpinion(true)}>
              <div style={{ flexDirection: 'column' }}>
                <MdAddBox
                  size={30}
                  color={currentPage == 'opinion' ? color.primary : 'gray'}
                  className='mx-auto'
                />
                <span style={{ fontWeight: '600', fontSize: '12px', color: currentPage == 'opinion' ? color.primary : 'gray' }}>
                  Opinion
                </span>
              </div>
            </Link>
          </li>

          <li>
            <Link to={'one-to-one'}>
              <div>
                <AiFillMessage
                  size={30}
                  color={currentPage == 'one-to-one' || currentPage == 'conversation' ? color.primary : 'gray'}
                  className='mx-auto'
                />
                <span style={{ fontWeight: '600', fontSize: '12px', color: currentPage == 'one-to-one' || currentPage == 'conversation' ? color.primary : 'gray' }}>
                  Inbox
                </span>
              </div>
            </Link>
          </li>

          <li>

            <Link to={'agora'}>
              <div style={{ flexDirection: 'column' }}>
                <MdGroups
                  size={30}
                  color={currentPage == 'agora' ? color.primary : 'gray'}
                  className='mx-auto'
                />
                <span style={{ fontWeight: '600', fontSize: '12px', color: currentPage == 'agora' ? color.primary : 'gray' }}>
                  Agoras
                </span>
              </div>
            </Link>
          </li>

        </ul>

      </nav>

      <ModalCreateOpinion
        user={AuthData?.user}
        show={showAddOpinion}
        setShow={setShowAddOpinion}
      />
    </div>
  )
}

export default NavigationBottom